import { setVideoContainerHeight, handleOrientationChange } from '../render/screen-actions.js';
import { checkOrientation } from '../render/screen-checks.js';

const executeIfProductTemplateLoaded = (fn) => {
    return (...args) => {
        if (window.isProductTemplateLoaded) {
            fn(...args);
        }
    };
};

function setupOrientationListener() {
    if (screen.orientation && screen.orientation.addEventListener) {
        screen.orientation.addEventListener('change', executeIfProductTemplateLoaded(handleOrientationChange));
    } else if ('onorientationchange' in window) {
        window.addEventListener('orientationchange', executeIfProductTemplateLoaded(handleOrientationChange));
    } else {
        window.addEventListener('resize', executeIfProductTemplateLoaded(handleOrientationChange));
    }
    window.addEventListener('load', executeIfProductTemplateLoaded(checkOrientation));
}

window.addEventListener('resize', executeIfProductTemplateLoaded(setVideoContainerHeight));
setupOrientationListener();