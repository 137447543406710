/* global videojs */

export function createThumbnailElement(src, className) {
    const thumbnailElement = document.createElement('img');
    thumbnailElement.src = src;
    thumbnailElement.className = className;
    return thumbnailElement;
}

export function addTouchHandlers(item, index, callback) {
    let isScrolling = false;
    let clickTimeout;

    item.on('touchstart', (event) => {
        event.preventDefault();
        isScrolling = false;
        clickTimeout = setTimeout(() => {
            callback(index);
        }, 2000);
    });

    item.on('touchmove', () => {
        isScrolling = true;
        clearTimeout(clickTimeout);
    });

    item.on('touchend', (event) => {
        if (!isScrolling) {
            clearTimeout(clickTimeout);
            callback(index);
        }
    });
}

let firstTimePlay = true;

export function loadScript(url) {
    return new Promise((resolve, reject) => {
        var script = document.createElement('script');
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
}

function toTitleCase(string) {
    if (typeof string !== 'string') {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function seekVideo(directionString) {
    var player = videojs.getAllPlayers()[0];
    directionString === "forward" ? player.currentTime(player.currentTime() + 10) : player.currentTime(player.currentTime() - 10);
}

export function requestFullScreen() {
    const player = videojs.getAllPlayers()[0];
    if (!videojs.browser.IS_IOS) {
        player.requestFullscreen();
        if (firstTimePlay && !window.isDashSource) {
            player.playlist.currentItem(videojs.getAllPlayers()[0].playlist.currentIndex() + 1);
            player.playlist.currentItem(videojs.getAllPlayers()[0].playlist.currentIndex() - 1);
            firstTimePlay = false;
        }
    } else {

        DeviceOrientationEvent.requestPermission();
        const fullScreenButton = player.controlBar.getChild('fullscreenToggle');
        var fullscreenControl = document.querySelector('.vjs-fullscreen-control');
        fullscreenControl.classList.remove('vjs-hidden');
        fullScreenButton.off('touchstart');
        fullScreenButton.on('touchstart', function () {
            if (player.isFullWindow) {
                player.exitFullWindow();
            } else {
                player.enterFullWindow();
            }
        });
        fullScreenButton.off('click');
        fullScreenButton.on('click', function () {
            if (player.isFullWindow) {
                player.exitFullWindow();
            } else {
                player.enterFullWindow();
            }
        });
        player.enterFullWindow();
    }
}

export function safelyRemoveControlBarChild(player, childName) {
    var child = player.controlBar.getChild(childName);
    if (child) {
        player.controlBar.removeChild(child);
    }
}

export function generateAndPreloadVideoList() {
    var videoPlayList = [];

    for (var i = 0; i < window.sources.length; i++) {
        const type = 'application/x-mpegURL';

        videoPlayList.push({ sources: [{ src: window.sources[i], type }] });

        var preloadLink = document.createElement('link');
        preloadLink.href = window.sources[i];
        preloadLink.rel = 'preload';
        preloadLink.as = 'fetch';
        preloadLink.type = type;

        if (new URL(window.sources[i]).origin !== window.location.origin) {
            preloadLink.crossOrigin = 'anonymous';
        }
        document.head.appendChild(preloadLink);
    }

    return videoPlayList;
}

export function setBigPlayButtonVisibility(player, visible) {
    const bigPlayButton = document.getElementsByClassName('vjs-big-vr-play-button');
    if (bigPlayButton.length > 0) {
        bigPlayButton[0].style.display = visible ? 'unset' : 'none';
    }
}

window.seekVideo = seekVideo;