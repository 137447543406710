/*global videojs*/

import { selectMenuItem } from './menu-handlers';

export function changeAudio(requestedAudioName, audioItems) {
    const player = videojs.getAllPlayers()[0];

    if (window.isDashSource) {
        const audioTracks = window.dashMediaPlayer.getTracksFor('audio');
        for (let i = 0; i < audioTracks.length; i++) {
            const audioName = audioTracks[i].lang || audioTracks[i].label || "Audio " + (i + 1);
            if (audioName === requestedAudioName) {
                window.dashMediaPlayer.setCurrentTrack(audioTracks[i]);
                break;
            }
        }
        localStorage.setItem('selectedAudioLabel', requestedAudioName);
    } else {
        const audioTracks = player.audioTracks();
        for (let i = 0; i < audioTracks.length; i++) {
            const audioTrack = audioTracks[i];
            const audioName = audioTrack.label ? audioTrack.label : "Audio " + (i + 1);
            audioTrack.enabled = (audioName === requestedAudioName);
        }
        localStorage.setItem('selectedAudioLabel', requestedAudioName);
    }

    selectMenuItem(audioItems, 'selectedAudioLabel');
}

window.changeAudio = changeAudio;