/* global videojs */

import './video.js/dist/video-js.css';
import { initializePlayerEvents } from './events/playerEvents';
import './events/windowEvents';
import InfoPopup from './components/info-popup';
import exitButton from './components/exit-button';
import switchAudioButton from './components/audio-tracks-menu';
import switchCameraButton from './components/camera-tracks-menu';
import switchQualityButton from './components/quality-levels-menu';
import SubtitlesMenuButton from './components/closed-captions-menu';
import OnscreenControls from './components/onscreen-controls';
import { generateAndPreloadVideoList, safelyRemoveControlBarChild, setBigPlayButtonVisibility } from "./utils/utils";
import {enablePinchZoom, setVideoContainerHeight} from "./render/screen-actions";
import { dashMediaPlayerSettings, initVROptions, playerOptions, seekButtonsOptions, vrOptions, xrOptions } from "./utils/player-options";

window.isDashSource = false;

const intorealityPlayer = function() {
    const player = this;

    if (window.MediaSource && window.sourcesDash && window.sourcesDash.trim() !== '') {
        window.sources = [window.sourcesDash];
        player.src({ src: window.sources[0], type: 'application/dash+xml' });
        window.isDashSource = true;
    } else {
        player.playlist(generateAndPreloadVideoList());
        player.playlist.currentItem(0);
    }

    if (videojs.browser.IS_IOS) {
        player.vr(vrOptions);
    } else {
        player.xr(xrOptions);
    }

    player.one('loadedmetadata', () => {
        setVideoContainerHeight();

        window.dashMediaPlayer = player.dash && player.dash.mediaPlayer;
        if (dashMediaPlayer) {
            dashMediaPlayer.updateSettings(dashMediaPlayerSettings);
        }

        const exitButtonInstance = player.addChild('ExitButtonPlugin', {});
        player.el().appendChild(exitButtonInstance.el());

        new OnscreenControls(player, { seekButtonsOptions });

        player.getChild('controlBar').addChild('SwitchCameraButton', {});
        player.getChild('controlBar').addChild('SwitchAudioButton', {});
        player.getChild('controlBar').addChild('SubtitlesMenuButton', {});
        player.getChild('controlBar').addChild('SwitchQualityButton', {});

        safelyRemoveControlBarChild(player, 'PictureInPictureToggle');
        safelyRemoveControlBarChild(player, 'ChaptersButton');
        safelyRemoveControlBarChild(player, 'subsCapsButton');

        player.getChild('controlBar').addChild('InfoPopup', {});

        if (videojs.browser.IS_ANY_SAFARI) {
            player.on('playing', function() {
                //setInterval(function() { checkForBlackScreen(true); }, 2000);
            });
        }
        enablePinchZoom(player);
        setBigPlayButtonVisibility(player, true);
        initializePlayerEvents(player);
    });
};

videojs.registerPlugin('intorealityPlayer', intorealityPlayer);

export function initIRPlayer(playerId, eventInformation) {
    const player = videojs(playerId, playerOptions);

    window.audioNames = eventInformation.audio_names;
    window.camNames = eventInformation.cam_names;
    window.camImages = eventInformation.cam_imgs;
    window.audioImages = eventInformation.audio_imgs;
    window.captionsImages = eventInformation.captions_imgs;
    window.videoTypes = eventInformation.video_types;
    window.allowedReferers = eventInformation.allowed_referers;
    window.canBeIframe = eventInformation.can_be_iframe;
    window.productId = eventInformation.product_id;
    window.sources = eventInformation.sources;
    window.sourcesDash = eventInformation.sources_dash;

    initVROptions();

    player.intorealityPlayer();
}

window.initIRPlayer = initIRPlayer;
export default intorealityPlayer;