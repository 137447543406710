/* global videojs */

import { captureAndShowFrame, removeCapturedFrame } from "../render/screen-actions";
import { selectMenuItem } from "./menu-handlers";

var player = null;
let wasMuted = null;
let savedCameraPosition = null;

export function changeCam(requestedCamera, cameraItems) {
    player = videojs.getAllPlayers()[0];
    var currentVideoProjection = videojs.browser.IS_IOS
        ? player.vr().currentProjection_
        : player.xr().options.projection;
    var playerCameraPosition = videojs.browser.IS_IOS
        ? player.vr().camera.position
        : player.xr().camera.position;
    if ((currentVideoProjection !== "2D" && currentVideoProjection !== "NONE") && (window.videoTypes[requestedCamera] === "2D" || window.videoTypes[requestedCamera] === "NONE")) {
        savedCameraPosition = playerCameraPosition;
    }

    if (!window.isDashSource) {
        wasMuted = player.muted();
        player.muted(true);
    }

    let customCurrentTime = null;
    let currentAudioTrackLabel = null;

    if (!window.isDashSource) {
        customCurrentTime = player.currentTime();

        for (let i = 0; i < player.audioTracks().length; i++) {
            if (player.audioTracks()[i].enabled) {
                currentAudioTrackLabel = player.audioTracks()[i].label
                    ? player.audioTracks()[i].label
                    : "Audio " + (i + 1);
                localStorage.setItem("selectedAudioLabel", currentAudioTrackLabel);
                localStorage.setItem("selectedQualityIndex", "0");
                break;
            }
        }
    }

    if (typeof player.vr == 'function') {
        if (player.vr().currentProjection_ != window.videoTypes[requestedCamera]) {
            if (window.videoTypes[requestedCamera] == "2D" || window.videoTypes[requestedCamera] == "NONE") {
                player.vr().setProjection("NONE");
            } else {
                player.vr().setProjection(window.videoTypes[requestedCamera]);
            }
        }
        if(window.isDashSource) {
            player.vr().init();
        }
    } else {
        if (player.xr().options.projection != window.videoTypes[requestedCamera]) {
            player.xr().options.projection = window.videoTypes[requestedCamera];
        }
    }

    if (!window.isDashSource) {
        captureAndShowFrame(player, currentVideoProjection);
    }

    if (window.isDashSource) {
        if (window.dashMediaPlayer && typeof window.dashMediaPlayer.getTracksFor === "function") {
            var videoTracks = window.dashMediaPlayer.getTracksFor("video");

            if (requestedCamera >= 0 && requestedCamera < videoTracks.length) {
                window.dashMediaPlayer.setCurrentTrack(videoTracks[requestedCamera]);
            } else {
                console.warn("Requested camera index is out of bounds.");
            }
        } else {
            console.warn("dashMediaPlayer is not available.");
        }
    } else {
        player.playlist.currentItem(requestedCamera);
    }

    if (!window.isDashSource) {
        player.one("loadedmetadata", function () {
            const selectedSubtitleLabel = localStorage.getItem("selectedSubtitleLabel") || "Disabled";
            const textTracks = player.textTracks();

            for (let i = 0; i < textTracks.length; i++) {
                const track = textTracks[i];
                if (track.kind === "subtitles") {
                    if (selectedSubtitleLabel === "Disabled") {
                        track.mode = "disabled";
                    } else if (track.label === selectedSubtitleLabel) {
                        track.mode = "showing";
                    } else {
                        track.mode = "disabled";
                    }
                }
            }

            const subtitlesButton = player.controlBar.getChild("SubtitlesMenuButton");
            if (subtitlesButton) {
                subtitlesButton.updateMenuItems(textTracks);
            }
        });
    }

    if (!window.isDashSource) {
        player.currentTime(customCurrentTime);
    }

    player.userActive(false);

    player.one('seeked', function () {
        selectMenuItem(cameraItems, 'selectedCameraIndex');

        if ((currentVideoProjection !== "2D" && currentVideoProjection !== "NONE") && (window.videoTypes[requestedCamera] !== "2D" && window.videoTypes[requestedCamera] !== "NONE")) {
            if (!videojs.browser.IS_IOS) {
                player.xr().camera.position.y = playerCameraPosition.y;
                player.xr().camera.position.x = playerCameraPosition.x;
                player.xr().camera.position.z = playerCameraPosition.z;
            }
        }

        player.one('progress', function () {
            if (!window.isDashSource) {
                const audioTracks = player.audioTracks();
                const selectedAudioLabel = localStorage.getItem('selectedAudioLabel');
                if (selectedAudioLabel) {
                    for (let i = 0; i < audioTracks.length; i++) {
                        const audioTrack = audioTracks[i];
                        const audioName = audioTrack.label ? audioTrack.label : "Audio " + (i + 1);
                        audioTrack.enabled = (audioName === selectedAudioLabel);
                    }
                }

                if (!wasMuted) {
                    player.muted(false);
                }
            }

            if (videojs.browser.IS_IOS) {
                var textTrackDisplayElement = document.querySelector('.vjs-text-track-display');
                if (textTrackDisplayElement && textTrackDisplayElement.classList.contains('vjs-hidden')) {
                    textTrackDisplayElement.classList.remove('vjs-hidden');
                }
            }
            const waitForStablePlayback = () => {
                const playbackTime = player.currentTime();
                const buffered = player.buffered();

                if (
                    playbackTime > customCurrentTime + 0.00 &&
                    !player.paused() &&
                    player.readyState() >= 4 &&
                    buffered.length > 0 &&
                    buffered.end(0) > playbackTime
                ) {
                    removeCapturedFrame();
                    player.off('timeupdate', waitForStablePlayback);
                }
            };
            player.on('timeupdate', waitForStablePlayback);
        });
    });

    if (window.videoTypes[requestedCamera] == "NONE" || window.videoTypes[requestedCamera] == "2D") {
        player.el().classList.add("force-default-cursor");
    } else {
        player.el().classList.remove("force-default-cursor");
    }

    if (!videojs.browser.IS_IOS) {
        window.updateProjectionEnvironment(window.videoTypes[requestedCamera]);
    }

    if (!window.isDashSource) {
        player.one("playlistitem", function () {
            player.one("loadeddata", function () {
                if (videojs.browser.IS_IOS) {
                    player.controlBar.fullscreenToggle.show();
                    var fullscreenControl = document.querySelector(".vjs-fullscreen-control");
                    fullscreenControl.classList.remove("vjs-hidden");
                }
            });
        });
    }
    if (window.isDashSource && typeof player.xr === 'function' && currentVideoProjection != player.xr().options.projection) {
        const trackChangeHandler = (event) => {
            if (event.mediaType === "video") {
                const playbackProgressHandler = () => {
                    player.xr().init();
                    if ((currentVideoProjection === "2D" || currentVideoProjection === "NONE") && (window.videoTypes[requestedCamera] !== "2D" && window.videoTypes[requestedCamera] !== "NONE")) {
                        if (savedCameraPosition) {
                            if (!videojs.browser.IS_IOS) {
                                player.xr().camera.position.x = savedCameraPosition.x;
                                player.xr().camera.position.y = savedCameraPosition.y;
                                player.xr().camera.position.z = savedCameraPosition.z;
                            }
                        }
                    }
                    window.dashMediaPlayer.off(dashjs.MediaPlayer.events.PLAYBACK_PROGRESS, playbackProgressHandler);
                    window.dashMediaPlayer.off(dashjs.MediaPlayer.events.TRACK_CHANGE_RENDERED, trackChangeHandler);
                };
                window.dashMediaPlayer.on(dashjs.MediaPlayer.events.PLAYBACK_PROGRESS, playbackProgressHandler);
            }
        };
        window.dashMediaPlayer.on(dashjs.MediaPlayer.events.TRACK_CHANGE_RENDERED, trackChangeHandler);
    }
    player.play();
}

window.changeCam = changeCam;